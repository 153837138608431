import { graphql } from "gatsby"

import { AcfAccordionBlockPreview } from "@/components/blocks/acf/AcfAccordionBlock";
import { AcfAdvertSliderBlockPreview } from "@/components/blocks/acf/AcfAdvertSliderBlock";
import { AcfBackgroundContainerBlockPreview } from "@/components/blocks/acf/AcfBackgroundContainerBlock";
import { AcfBackgroundImageContainerBlockPreview } from "@/components/blocks/acf/AcfBackgroundImageContainerBlock";
import { AcfBrewGalleryBlockPreview } from "@/components/blocks/acf/AcfBrewGalleryBlock";
import { AcfButtonBlockPreview } from "@/components/blocks/acf/AcfButtonBlock";
import { AcfFacebookPagePluginBlockPreview } from "@/components/blocks/acf/AcfFacebookPagePluginBlock";
import { AcfFourCornerGalleryMessageBlockPreview } from "@/components/blocks/acf/AcfFourCornerGalleryMessageBlock";
import { AcfGlobalContactInformationImageBlockPreview } from "@/components/blocks/acf/AcfGlobalContactInformationImageBlock";
import { AcfGoogleReviewsBlockPreview } from "@/components/blocks/acf/AcfGoogleReviewsBlock";
import { AcfHtmlBlockPreview } from "@/components/blocks/acf/AcfHtmlBlock";
import { AcfImageSliderBlockPreview } from "@/components/blocks/acf/AcfImageSliderBlock";
import { AcfJobsListBlockPreview } from "@/components/blocks/acf/AcfJobsListBlock";
import { AcfLandingSplashBannerBlockPreview } from "@/components/blocks/acf/AcfLandingSplashBannerBlock";
import { AcfLatestPostsGridBlockPreview } from "@/components/blocks/acf/AcfLatestPostsGridBlock";
import { AcfLatestPostsSliderBlockPreview } from "@/components/blocks/acf/AcfLatestPostsSliderBlock";
import { AcfLogoRowBlockPreview } from "@/components/blocks/acf/AcfLogoRowBlock";
import { AcfLogoSliderBlockPreview } from "@/components/blocks/acf/AcfLogoSliderBlock";
import { AcfPageListingBlockPreview } from "@/components/blocks/acf/AcfPageListingBlock";
import { AcfPdfReaderBlockPreview } from "@/components/blocks/acf/AcfPdfReaderBlock";
import { AcfPeopleGridBlockPreview } from "@/components/blocks/acf/AcfPeopleGridBlock";
import { AcfTimedContainerBlockPreview } from "@/components/blocks/acf/AcfTimedContainerBlock";
import { CoreBlockPreview } from "@/components/blocks/core/CoreBlock";
import { CoreHeadingBlockPreview } from "@/components/blocks/core/CoreHeadingBlock";
import { CoreImageBlockPreview } from "@/components/blocks/core/CoreImageBlock";
import { CoreListBlockPreview } from "@/components/blocks/core/CoreListBlock";
import { CoreParagraphBlockPreview } from "@/components/blocks/core/CoreParagraphBlock";
import { CoreSpacerBlockPreview } from "@/components/blocks/core/CoreSpacerBlock";
import { CoreTableBlockPreview } from "@/components/blocks/core/CoreTableBlock";
import { BblBannerMessageBlockPreview } from "@/components/blocks/bbl/BblBannerMessageBlock";
import { BblBannerMessageSliderBlockPreview } from "@/components/blocks/bbl/BblBannerMessageSliderBlock";
import { BblBannerNavSliderBlockPreview } from "@/components/blocks/bbl/BblBannerNavSliderBlock";
import { BblBannerNavSliderInnerBlockPreview } from "@/components/blocks/bbl/BblBannerNavSliderInnerBlock";
import { BblBannerStackedBlockPreview } from "@/components/blocks/bbl/BblBannerStackedBlock";
import { BblBranchIntroBlockPreview } from "@/components/blocks/bbl/BblBranchIntroBlock";
import { BblBranchListingBlockPreview } from "@/components/blocks/bbl/BblBranchListingBlock";
import { BblBrandDetailBlockPreview } from "@/components/blocks/bbl/BblBrandDetailBlock";
import { BblBrandLogosBlockPreview } from "@/components/blocks/bbl/BblBrandLogosBlock";
import { BblCalloutCtaBlockPreview } from "@/components/blocks/bbl/BblCalloutCtaBlock";
import { BblCalloutCtaDescriptionBlockPreview } from "@/components/blocks/bbl/BblCalloutCtaDescriptionBlock";
import { BblCardBlockPreview } from "@/components/blocks/bbl/BblCardBlock";
import { BblCardRowBlockPreview } from "@/components/blocks/bbl/BblCardRowBlock";
import { BblContactBannerBlockPreview } from "@/components/blocks/bbl/BblContactBannerBlock";
import { BblContactCalloutBlockPreview } from "@/components/blocks/bbl/BblContactCalloutBlock";
import { BblContactInformationImageBlockPreview } from "@/components/blocks/bbl/BblContactInformationImageBlock";
import { BblContactInformationMapBlockPreview } from "@/components/blocks/bbl/BblContactInformationMapBlock";
import { BblFeaturedIconsBlockPreview } from "@/components/blocks/bbl/BblFeaturedIconsBlock";
import { BblFeaturedIconsInnerBlockPreview } from "@/components/blocks/bbl/BblFeaturedIconsInnerBlock";
import { BblFiftyStackedContentBlockPreview } from "@/components/blocks/bbl/BblFiftyStackedContentBlock";
import { BblFormIntroBlockPreview } from "@/components/blocks/bbl/BblFormIntroBlock";
import { BblGoogleMapBlockPreview } from "@/components/blocks/bbl/BblGoogleMapBlock";
import { BblIconRowBlockPreview } from "@/components/blocks/bbl/BblIconRowBlock";
import { BblIconRowInnerBlockPreview } from "@/components/blocks/bbl/BblIconRowInnerBlock";
import { BblImageContentRowsBlockPreview } from "@/components/blocks/bbl/BblImageContentRowsBlock";
import { BblImageContentRowsInnerBlockPreview } from "@/components/blocks/bbl/BblImageContentRowsInnerBlock";
import { BblImageLinkSliderBlockPreview } from "@/components/blocks/bbl/BblImageLinkSliderBlock";
import { BblImageLinkSliderInnerBlockPreview } from "@/components/blocks/bbl/BblImageLinkSliderInnerBlock";
import { BblIntroGalleryBlockPreview } from "@/components/blocks/bbl/BblIntroGalleryBlock";
import { BblJobDetailBlockPreview } from "@/components/blocks/bbl/BblJobDetailBlock";
import { BblManagerInfoBlockPreview } from "@/components/blocks/bbl/BblManagerInfoBlock";
import { BblMediaItemBlockPreview } from "@/components/blocks/bbl/BblMediaItemBlock";
import { BblOverlapBannerBlockPreview } from "@/components/blocks/bbl/BblOverlapBannerBlock";
import { BblPageTitleBlockPreview } from "@/components/blocks/bbl/BblPageTitleBlock";
import { BblPageTitleImageBlockPreview } from "@/components/blocks/bbl/BblPageTitleImageBlock";
import { BblPostBannerBlockPreview } from "@/components/blocks/bbl/BblPostBannerBlock";
import { BblPostListingCardGridBlockPreview } from "@/components/blocks/bbl/BblPostListingCardGridBlock";
import { BblPostListingFullWidthBlockPreview } from "@/components/blocks/bbl/BblPostListingFullWidthBlock";
import { BblPostWrapBlockPreview } from "@/components/blocks/bbl/BblPostWrapBlock";
import { BblProductGridBlockPreview } from "@/components/blocks/bbl/BblProductGridBlock";
import { BblSideCardBlockPreview } from "@/components/blocks/bbl/BblSideCardBlock";
import { BblSideCardGridBlockPreview } from "@/components/blocks/bbl/BblSideCardGridBlock";
import { BblSplitPageTitleBlockPreview } from "@/components/blocks/bbl/BblSplitPageTitleBlock";
import { BblSplitPostTitleBlockPreview } from "@/components/blocks/bbl/BblSplitPostTitleBlock";
import { BblStatBreakdownBlockPreview } from "@/components/blocks/bbl/BblStatBreakdownBlock";
import { BblStatBreakdownInnerBlockPreview } from "@/components/blocks/bbl/BblStatBreakdownInnerBlock";
import { BblStickyPostFullWidthBlockPreview } from "@/components/blocks/bbl/BblStickyPostFullWidthBlock";
import { BblStickyPostSplitBlockPreview } from "@/components/blocks/bbl/BblStickyPostSplitBlock";
import { BblTestimonialBlockPreview } from "@/components/blocks/bbl/BblTestimonialBlock";
import { BblTestimonialSliderBlockPreview } from "@/components/blocks/bbl/BblTestimonialSliderBlock";
import { BblTextIntroBlockPreview } from "@/components/blocks/bbl/BblTextIntroBlock";
import { BblTwoColumnTextIntroBlockPreview } from "@/components/blocks/bbl/BblTwoColumnTextIntroBlock";
import { BblTwoColumnWrapperBlockPreview } from "@/components/blocks/bbl/BblTwoColumnWrapperBlock";
import { BblTwoColumnWrapperInnerBlockPreview } from "@/components/blocks/bbl/BblTwoColumnWrapperInnerBlock";
import { BblTwoLevelHeadingBlockPreview } from "@/components/blocks/bbl/BblTwoLevelHeadingBlock";
import { BblVideoBannerMessageBlockPreview } from "@/components/blocks/bbl/BblVideoBannerMessageBlock";
import { GravityformsFormBlockPreview } from "@/components/blocks/misc/GravityformsFormBlock";

export const CoreFields = graphql`
	fragment CoreFields on WpBlock {
		name
		...AcfAccordionBlock
		...AcfAdvertSliderBlock
		...AcfBackgroundContainerBlock
		...AcfBackgroundImageContainerBlock
		...AcfBrewGalleryBlock
		...AcfButtonBlock
		...AcfFacebookPagePluginBlock
		...AcfFourCornerGalleryMessageBlock
		...AcfGlobalContactInformationImageBlock
		...AcfGoogleReviewsBlock
		...AcfHtmlBlock
		...AcfImageSliderBlock
		...AcfJobsListBlock
		...AcfLandingSplashBannerBlock
		...AcfLatestPostsGridBlock
		...AcfLatestPostsSliderBlock
		...AcfLogoRowBlock
		...AcfLogoSliderBlock
		...AcfPageListingBlock
		...AcfPdfReaderBlock
		...AcfPeopleGridBlock
		...AcfTimedContainerBlock
		...CoreBlock
		...CoreHeadingBlock
		...CoreImageBlock
		...CoreListBlock
		...CoreParagraphBlock
		...CoreSpacerBlock
		...CoreTableBlock
		...BblBannerMessageBlock
		...BblBannerMessageSliderBlock
		...BblBannerNavSliderBlock
		...BblBannerNavSliderInnerBlock
		...BblBannerStackedBlock
		...BblBranchIntroBlock
		...BblBranchListingBlock
		...BblBrandDetailBlock
		...BblBrandLogosBlock
		...BblCalloutCtaBlock
		...BblCalloutCtaDescriptionBlock
		...BblCardBlock
		...BblCardRowBlock
		...BblContactBannerBlock
		...BblContactCalloutBlock
		...BblContactInformationImageBlock
		...BblContactInformationMapBlock
		...BblFeaturedIconsBlock
		...BblFeaturedIconsInnerBlock
		...BblFiftyStackedContentBlock
		...BblFormIntroBlock
		...BblGoogleMapBlock
		...BblIconRowBlock
		...BblIconRowInnerBlock
		...BblImageContentRowsBlock
		...BblImageContentRowsInnerBlock
		...BblImageLinkSliderBlock
		...BblImageLinkSliderInnerBlock
		...BblIntroGalleryBlock
		...BblJobDetailBlock
		...BblManagerInfoBlock
		...BblMediaItemBlock
		...BblOverlapBannerBlock
		...BblPageTitleBlock
		...BblPageTitleImageBlock
		...BblPostBannerBlock
		...BblPostListingCardGridBlock
		...BblPostListingFullWidthBlock
		...BblPostWrapBlock
		...BblProductGridBlock
		...BblSideCardBlock
		...BblSideCardGridBlock
		...BblSplitPageTitleBlock
		...BblSplitPostTitleBlock
		...BblStatBreakdownBlock
		...BblStatBreakdownInnerBlock
		...BblStickyPostFullWidthBlock
		...BblStickyPostSplitBlock
		...BblTestimonialBlock
		...BblTestimonialSliderBlock
		...BblTextIntroBlock
		...BblTwoColumnTextIntroBlock
		...BblTwoColumnWrapperBlock
		...BblTwoColumnWrapperInnerBlock
		...BblTwoLevelHeadingBlock
		...BblVideoBannerMessageBlock
		...GravityformsFormBlock
	}
`;

export const PreviewBlocks = `
	${AcfAccordionBlockPreview}
	${AcfAdvertSliderBlockPreview}
	${AcfBackgroundContainerBlockPreview}
	${AcfBackgroundImageContainerBlockPreview}
	${AcfBrewGalleryBlockPreview}
	${AcfButtonBlockPreview}
	${AcfFacebookPagePluginBlockPreview}
	${AcfFourCornerGalleryMessageBlockPreview}
	${AcfGlobalContactInformationImageBlockPreview}
	${AcfGoogleReviewsBlockPreview}
	${AcfHtmlBlockPreview}
	${AcfImageSliderBlockPreview}
	${AcfJobsListBlockPreview}
	${AcfLandingSplashBannerBlockPreview}
	${AcfLatestPostsGridBlockPreview}
	${AcfLatestPostsSliderBlockPreview}
	${AcfLogoRowBlockPreview}
	${AcfLogoSliderBlockPreview}
	${AcfPageListingBlockPreview}
	${AcfPdfReaderBlockPreview}
	${AcfPeopleGridBlockPreview}
	${AcfTimedContainerBlockPreview}
	${CoreBlockPreview}
	${CoreHeadingBlockPreview}
	${CoreImageBlockPreview}
	${CoreListBlockPreview}
	${CoreParagraphBlockPreview}
	${CoreSpacerBlockPreview}
	${CoreTableBlockPreview}
	${BblBannerMessageBlockPreview}
	${BblBannerMessageSliderBlockPreview}
	${BblBannerNavSliderBlockPreview}
	${BblBannerNavSliderInnerBlockPreview}
	${BblBannerStackedBlockPreview}
	${BblBranchIntroBlockPreview}
	${BblBranchListingBlockPreview}
	${BblBrandDetailBlockPreview}
	${BblBrandLogosBlockPreview}
	${BblCalloutCtaBlockPreview}
	${BblCalloutCtaDescriptionBlockPreview}
	${BblCardBlockPreview}
	${BblCardRowBlockPreview}
	${BblContactBannerBlockPreview}
	${BblContactCalloutBlockPreview}
	${BblContactInformationImageBlockPreview}
	${BblContactInformationMapBlockPreview}
	${BblFeaturedIconsBlockPreview}
	${BblFeaturedIconsInnerBlockPreview}
	${BblFiftyStackedContentBlockPreview}
	${BblFormIntroBlockPreview}
	${BblGoogleMapBlockPreview}
	${BblIconRowBlockPreview}
	${BblIconRowInnerBlockPreview}
	${BblImageContentRowsBlockPreview}
	${BblImageContentRowsInnerBlockPreview}
	${BblImageLinkSliderBlockPreview}
	${BblImageLinkSliderInnerBlockPreview}
	${BblIntroGalleryBlockPreview}
	${BblJobDetailBlockPreview}
	${BblManagerInfoBlockPreview}
	${BblMediaItemBlockPreview}
	${BblOverlapBannerBlockPreview}
	${BblPageTitleBlockPreview}
	${BblPageTitleImageBlockPreview}
	${BblPostBannerBlockPreview}
	${BblPostListingCardGridBlockPreview}
	${BblPostListingFullWidthBlockPreview}
	${BblPostWrapBlockPreview}
	${BblProductGridBlockPreview}
	${BblSideCardBlockPreview}
	${BblSideCardGridBlockPreview}
	${BblSplitPageTitleBlockPreview}
	${BblSplitPostTitleBlockPreview}
	${BblStatBreakdownBlockPreview}
	${BblStatBreakdownInnerBlockPreview}
	${BblStickyPostFullWidthBlockPreview}
	${BblStickyPostSplitBlockPreview}
	${BblTestimonialBlockPreview}
	${BblTestimonialSliderBlockPreview}
	${BblTextIntroBlockPreview}
	${BblTwoColumnTextIntroBlockPreview}
	${BblTwoColumnWrapperBlockPreview}
	${BblTwoColumnWrapperInnerBlockPreview}
	${BblTwoLevelHeadingBlockPreview}
	${BblVideoBannerMessageBlockPreview}
	${GravityformsFormBlockPreview}
`